import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Typography,
  Box,
  Button,
  IconButton,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Styled TableContainer with a polished dark theme and custom scrollbar
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400,
  backgroundColor: '#1C1C1E',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#1C1C1E',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#3A3A3C',
    borderRadius: '4px',
    border: '2px solid #1C1C1E',
  },
  scrollbarWidth: 'thin',
  scrollbarColor: '#3A3A3C #1C1C1E',
}));

// Styled table cell for consistent typography and spacing
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#FFFFFF',
  fontWeight: 500,
  borderBottom: 'none',
  padding: theme.spacing(1.5),
}));

// Styled table row with a smooth hover animation
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.3s, transform 0.3s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#2C2C2E',
    transform: 'scale(1.01)',
  },
}));

// New styled cell to truncate long content
const TruncatedContentCell = styled(StyledTableCell)(({ theme }) => ({
  maxWidth: 200,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

// Container for the side drawer
const DrawerContainer = styled(Box)(({ theme }) => ({
  width: 400,
  padding: theme.spacing(3),
  backgroundColor: '#1C1C1E',
  height: '100%',
  position: 'relative',
}));

// Close button for the drawer
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: '#FFFFFF',
}));

const FindingsTable = ({ findings }) => {
  const [selectedFinding, setSelectedFinding] = useState(null);

  // Dummy data
  const dummyData = [
    {
      date: '2024-01-11',
      type: 'VIP Protection Alert',
      detail: 'Detected messages mentioning a high-profile individual in a monitored Telegram group.',
      extraInfo: 'Further investigation suggested that the individual was mentioned in hostile discussions.',
    },
    {
      date: '2024-01-12',
      type: 'Suspicious Activity',
      detail: 'Potential phishing attempt targeting VIP individuals identified in a monitored Telegram chat.',
      extraInfo: 'Multiple phishing messages detected in the chat. Users are advised to be cautious.',
    },
    {
      date: '2024-01-15',
      type: 'Threat Intelligence',
      detail: 'Observed hostile discussions related to VIP security in a monitored Telegram channel.',
      extraInfo: 'Security measures are recommended as a precaution.',
    },
  ];

  const displayData = findings && findings.length > 0 ? findings : dummyData;

  // Row click opens the side drawer with details
  const handleRowClick = (finding) => {
    setSelectedFinding(finding);
  };

  // Close the drawer
  const handleClose = () => {
    setSelectedFinding(null);
  };

  // Format date to a readable string
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <StyledTableContainer component={Paper}>
        <Table stickyHeader aria-label="Findings table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Detail</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((finding, index) => (
              <StyledTableRow key={index} onClick={() => handleRowClick(finding)}>
                <StyledTableCell>{formatDate(finding.date)}</StyledTableCell>
                <StyledTableCell>{finding.type}</StyledTableCell>
                <StyledTableCell>{finding.detail}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Side Drawer for extra data with a fade-in transition */}
      <Drawer anchor="right" open={Boolean(selectedFinding)} onClose={handleClose}>
        <Fade in={Boolean(selectedFinding)}>
          <Box
            sx={{
              width: 400,
              padding: 3,
              backgroundColor: '#1C1C1E',
              height: '100%',
              position: 'relative',
            }}
          >
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            {selectedFinding && (
              <>
                <Typography variant="h5" sx={{ color: '#FFFFFF', fontWeight: 'bold', mb: 2 }}>
                  {selectedFinding.type}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#AAAAAA', mb: 1 }}>
                  {formatDate(selectedFinding.date)}
                </Typography>
                <Typography variant="body1" sx={{ color: '#FFFFFF', mb: 2 }}>
                  {selectedFinding.detail}
                </Typography>
                <Typography variant="body2" sx={{ color: '#BBBBBB' }}>
                  {selectedFinding.extraInfo || 'No additional information available.'}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                  fullWidth
                  onClick={handleClose}
                >
                  Close
                </Button>
              </>
            )}
          </Box>
        </Fade>
      </Drawer>
    </Box>
  );
};

export default FindingsTable;
