import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import {
  CircularProgress,
  CssBaseline,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProfilePage from './pages/ProfilePage';
import Login from './pages/login';
import Signup from './pages/Signup';
import SidebarNew from './pages/SidebarNew';
import SearchPage from './pages/SearchPage';
import SettingsPage from './pages/Settings';
import Actors from './pages/threatactors'
import './App.css';
import { firebaseApp } from './firebaseConfig'; // Ensure this file exists and exports your initialized app

const auth = getAuth(firebaseApp);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#0071E3' },
    background: { default: '#000' },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.72)',
        },
      },
    },
  },
});

// Allowed subscription statuses for full access
const allowedStatuses = ["trial", "trialing", "active"];

// Inline Renew Subscription Modal Component
function RenewSubscriptionModal({ open, onClose, onRefresh }) {
  const [selectedPlan, setSelectedPlan] = useState('essential');
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const subscriptionPlans = {
    essential: "price_1R0faBJyVnqloMx5MsGsSDsr",
    business: "price_1R0fjcJyVnqloMx5dSxFJt6m",
    enterprise: "price_1R0fk3JyVnqloMx5x6JruG6G"
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleRenew = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/api/renew-subscription',
        { price_id: subscriptionPlans[selectedPlan] },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error renewing subscription:", error);
      setError("Failed to renew subscription. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        onClose();
      }}
      disableEscapeKeyDown
      BackdropProps={{
        style: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          p: 3,
          background: 'rgba(255,255,255,0.9)',
          textAlign: 'center',
        },
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ fontWeight: 'bold', color: '#000' }}>
        Subscription Inactive
      </DialogTitle>
      <DialogContent sx={{ color: '#000' }}>
        <Box sx={{ mb: 2 }}>
          Your trial has ended or your subscription is inactive.
          Please select a plan to renew your subscription:
        </Box>
        <RadioGroup value={selectedPlan} onChange={handlePlanChange}>
          {Object.keys(subscriptionPlans).map((planKey) => (
            <FormControlLabel
              key={planKey}
              value={planKey}
              control={<Radio sx={{ color: "#0071E3" }} />}
              label={planKey.charAt(0).toUpperCase() + planKey.slice(1)}
            />
          ))}
        </RadioGroup>
        {error && (
          <Box sx={{ mt: 2, color: "#d0021b", fontSize: "0.9rem" }}>
            {error}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          onClick={handleRenew}
          sx={{
            borderRadius: '12px',
            textTransform: 'none',
            backgroundColor: "#0071E3",
            "&:hover": { backgroundColor: "#005bb5" },
          }}
        >
          Proceed
        </Button>
        <Button
          variant="outlined"
          onClick={handleLogout}
          sx={{
            borderRadius: '12px',
            textTransform: 'none',
            borderColor: "#ccc",
            color: "#000",
            "&:hover": { borderColor: "#bbb" },
          }}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [renewModalOpen, setRenewModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const location = useLocation();
  const navigate = useNavigate();

  const noSidebarRoutes = ['/login', '/signup'];

  const fetchData = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        const token = await currentUser.getIdToken(true);
        const response = await axios.get(
          'https://ln2jj4k2-3005.euw.devtunnels.ms/api/dashboard',
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  // Handle logout for both verification and other modals
  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Use Snackbar for messages
  const handleResendVerification = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      await axios.post(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/api/resend-verification',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbarMessage("Verification email sent. Please check your inbox.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to resend verification email. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Resend verification error:", error);
    }
  };

  // Check authentication state and fetch dashboard data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setIsAuthenticated(!!currentUser);
      setUser(currentUser);
      if (currentUser) {
        fetchData();
      } else {
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [fetchData]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Check email verification status every 6 seconds if the modal is open
  useEffect(() => {
    let interval;
    if (verifyModalOpen) {
      interval = setInterval(async () => {
        await auth.currentUser.reload();
        const updatedUser = auth.currentUser;
        setUser(updatedUser);
        if (updatedUser.emailVerified) {
          setVerifyModalOpen(false);
          clearInterval(interval);
        }
      }, 6000);
    }
    return () => clearInterval(interval);
  }, [verifyModalOpen]);

  // Open modals based on email verification and subscription status
  useEffect(() => {
    if (isAuthenticated && user && data) {
      if (!user.emailVerified) {
        setVerifyModalOpen(true);
        setRenewModalOpen(false);
      } else if (!allowedStatuses.includes(data.subscriptionStatus)) {
        setRenewModalOpen(true);
        setVerifyModalOpen(false);
      } else {
        setVerifyModalOpen(false);
        setRenewModalOpen(false);
      }
    }
  }, [isAuthenticated, user, data]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  const showSidebar = isAuthenticated && !noSidebarRoutes.includes(location.pathname);
  const mainBackground = location.pathname === "/settings" ? "inherit" : "#ffffff";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100vh' }}>
        {showSidebar && (
          <SidebarNew open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            minHeight: '100vh',
            backgroundColor: mainBackground,
          }}
        >
          {/* Modal for Email Not Verified */}
          {verifyModalOpen && (
            <Dialog
              open={true}
              onClose={(event, reason) => {
                if (reason === "backdropClick" || reason === "escapeKeyDown") return;
              }}
              disableEscapeKeyDown
              BackdropProps={{
                style: {
                  backdropFilter: 'blur(10px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: '16px',
                  p: 3,
                  background: 'rgba(255,255,255,0.9)',
                  textAlign: 'center',
                },
              }}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle sx={{ fontWeight: 'bold', color: '#000' }}>
                Email Not Verified
              </DialogTitle>
              <DialogContent sx={{ color: '#000' }}>
                Your email address is not verified. Please check your inbox and verify your email to access all features.
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleResendVerification}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    backgroundColor: "#0071E3",
                    "&:hover": { backgroundColor: "#005bb5" },
                  }}
                >
                  Resend Verification Email
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  sx={{
                    borderRadius: '12px',
                    textTransform: 'none',
                    borderColor: "#ccc",
                    color: "#000",
                    "&:hover": { borderColor: "#bbb" },
                  }}
                >
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* Modal for Subscription Inactive */}
          {renewModalOpen && (
            <RenewSubscriptionModal
              open={renewModalOpen}
              onClose={() => {}}
              onRefresh={() => {}}
            />
          )}

          <Routes>
            <Route path="/" element={isAuthenticated ? <Dashboard data={data} /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route
              path="/search"
              element={
                <Box
                  sx={{
                    height: '100vh',
                    width: '100%',
                    backgroundColor: '#ffffff',
                  }}
                >
                  {isAuthenticated ? <SearchPage /> : <Login setIsAuthenticated={setIsAuthenticated} />}
                </Box>
              }
            />
            <Route path="/settings" element={isAuthenticated ? <SettingsPage /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/actors" element={isAuthenticated ? <Actors /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </Box>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export { firebaseApp };
export default function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
