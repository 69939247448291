import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
  Tooltip,
  Drawer,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

// Components
import RecentCVE from './Components/RecentCVE';
import HeatMap from './Components/HeatMap';
import FindingsTable from './Components/FindingsTable';
import OverviewCard from './Components/OverviewCard';
import ThreatFeed from './Components/ThreatFeed';

// Import the default profile image (adjust path as needed)
import defaultProfile from './defualt.jpg';

// ====== Styled Components ====== //
const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#000',
}));

const VipCardsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const HorizontalScroll = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  overflowX: 'auto',
  paddingBottom: theme.spacing(2),
}));

// ====== Utility Function ====== //
const getSocialMediaLabel = (url) => {
  const domain = new URL(url.includes('http') ? url : `http://${url}`).hostname;
  if (domain.includes('facebook')) return 'Facebook';
  if (domain.includes('instagram')) return 'Instagram';
  if (domain.includes('snapchat')) return 'Snapchat';
  if (domain.includes('youtube')) return 'YouTube';
  if (domain.includes('twitter') || domain.includes('x.')) return 'Twitter';
  if (domain.includes('flickr')) return 'Flickr';
  return domain;
};

// ====== SectionTitle Component ====== //
function SectionTitle({ text, tooltip }) {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="h6" gutterBottom color="white">
        {text}
      </Typography>
      <Tooltip title={tooltip} arrow>
        <InfoIcon sx={{ color: 'grey', ml: 1, fontSize: '1.2rem' }} />
      </Tooltip>
    </Box>
  );
}

// ====== VIP Card Render Function ====== //
const renderVipCard = (vip, handleClick) => (
  <Box
    key={vip.id}
    onClick={() => handleClick(vip)}
    sx={{
      minWidth: 220,
      minHeight: 260,
      backgroundColor: '#fff',
      color: '#000',
      borderRadius: 8,
      boxShadow: 3,
      padding: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      flexShrink: 0,
    }}
  >
    <Box
      component="img"
      src={defaultProfile}
      alt="Profile"
      sx={{
        width: 80,
        height: 80,
        borderRadius: '50%',
        mx: 'auto',
        mb: 1,
        objectFit: 'cover',
      }}
    />
    <Typography variant="h6">
      {vip.firstName} {vip.lastName}
    </Typography>
  </Box>
);

// ====== VIP Details Modal Content ====== //
const renderVipModalContent = (selectedVip, userData) => {
  if (!selectedVip) return null;

  const vipDetections = userData?.vipDetections
    ? userData.vipDetections.filter(
        (d) =>
          d.vipFullName &&
          d.vipFullName.toLowerCase() ===
            (`${selectedVip.firstName} ${selectedVip.lastName}`).toLowerCase()
      )
    : [];

  const socialMediaButtons = [];
  vipDetections.forEach((det) => {
    if (det.docData && Array.isArray(det.docData.urls)) {
      det.docData.urls.forEach((url) => {
        if (!socialMediaButtons.some((btn) => btn.url === url)) {
          socialMediaButtons.push({ url, label: getSocialMediaLabel(url) });
        }
      });
    }
  });

  const credentialDetails = [];
  vipDetections.forEach((det) => {
    if (det.docData && Array.isArray(det.docData.emails)) {
      det.docData.emails.forEach((entry) => {
        if (entry.includes(":")) {
          const [email, password] = entry.split(":");
          credentialDetails.push({ email, password });
        }
      });
    }
  });

  return (
    <Box sx={{ p: 3, color: "#fff" }}>
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <Box
          component="img"
          src={defaultProfile}
          alt="Profile"
          sx={{
            width: 120,
            height: 120,
            borderRadius: "50%",
            mb: 1,
            objectFit: "cover",
          }}
        />
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {selectedVip.firstName} {selectedVip.lastName}
        </Typography>
      </Box>

      {credentialDetails.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
            Credentials
          </Typography>
          {credentialDetails.map((cred, idx) => (
            <Box key={idx} sx={{ mt: 1 }}>
              <Typography variant="body1">
                <strong>Email:</strong> {cred.email}
              </Typography>
              <Typography variant="body1">
                <strong>Password:</strong> {cred.password}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {socialMediaButtons.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
            Social Media
          </Typography>
          <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap" }}>
            {socialMediaButtons.map((btn, idx) => (
              <Button
                key={idx}
                variant="contained"
                sx={{ m: 0.5, textTransform: "none" }}
                onClick={() => window.open(btn.url, "_blank")}
              >
                {btn.label}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
          Profile Data
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Emails:</strong> {selectedVip.emails?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Phones:</strong> {selectedVip.phoneNumbers?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Credit Cards:</strong>{" "}
          {selectedVip.creditCardNumbers && selectedVip.creditCardNumbers.length > 0 ? (
            selectedVip.creditCardNumbers.map((card, idx) => (
              <Box
                key={idx}
                sx={{
                  backgroundColor: "#e0e0e0",
                  color: "#000",
                  borderRadius: 1,
                  p: 0.5,
                  mx: 0.5,
                  fontFamily: "monospace",
                  fontSize: "0.9rem",
                  display: "inline-block",
                }}
              >
                {card.slice(-4).padStart(card.length, "•")}
              </Box>
            ))
          ) : (
            "None"
          )}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>IBANs:</strong> {selectedVip.ibanNumbers?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Crypto Wallets:</strong> {selectedVip.cryptoWalletAddresses?.join(", ") || "None"}
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
          Detections
        </Typography>
        {vipDetections.length === 0 ? (
          <Typography>No detections for this VIP.</Typography>
        ) : (
          vipDetections.map((det, idx) => (
            <Box key={idx} sx={{ mb: 2, p: 2, border: "1px solid #ddd", borderRadius: 2, position: 'relative' }}>
              {det.isNew && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: 'orange'
                  }}
                />
              )}
              <Typography variant="subtitle1">
                Detection Type: {det.detectionType}
              </Typography>
              <Typography variant="body2">
                Date: {new Date(det.detectionDate).toLocaleString()}
              </Typography>
              {det.docData && Array.isArray(det.docData.urls) && det.docData.urls.length > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    URLs:
                  </Typography>
                  {det.docData.urls.map((url, uIdx) => (
                    <Typography variant="body2" key={uIdx}>
                      {url}
                    </Typography>
                  ))}
                </Box>
              )}
              {det.docData && det.docData.content && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Content snippet:
                  </Typography>
                  <Typography variant="body2">
                    {det.docData.content}
                  </Typography>
                </Box>
              )}
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

// ====== Dashboard Header Section for Overview ====== //
function DashboardHeaderSection({ data }) {
  const detections = data?.detections || [];
  const detectionCount = detections.length || 0;
  const reportCount = data?.searchCounter || 0;
  const unsolvedDetectionsCount = detections.filter(det => det.solved !== true).length || 0;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Detected Breaches Count"
          value={detectionCount.toLocaleString()}
          icon={<BusinessIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Number of Searches"
          value={reportCount.toLocaleString()}
          icon={<SecurityIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Monitoring Status"
          value="Active"
          icon={<AccessTimeIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Unsolved Threats"
          value={unsolvedDetectionsCount.toLocaleString()}
          icon={<AccessTimeIcon />}
        />
      </Grid>
    </Grid>
  );
}

// ====== Dashboard Sections Component (Stacked Layout) ====== //
function DashboardSections({ data, onVipClick }) {
  const lastLoginTime = data.lastLogin ? new Date(data.lastLogin) : null;
  const standardizedFindings = (data.detections || []).map((finding) => {
    let date, source, type, detail, id;
    if (finding.dateOfDetection && finding.port) {
      id = finding.id;
      date = finding.dateOfDetection;
      source = 'Network Scan';
      type = `Open Port - ${finding.port}`;
      detail = finding.detail;
    } else if (finding.date && finding.partitionKey) {
      id = finding.id;
      date = finding.date;
      source = finding.channel_name || 'Unknown Source';
      type = 'Compromised credentials';
      detail = finding.content || 'No details available';
    } else {
      id = finding.id || 'Unknown';
      date = finding.detectionDate || 'Unknown';
      source = 'Unknown Source';
      type = 'Unknown Type';
      detail = 'No details available';
    }
    const detection = { id, date, source, type, detail };
    if (date !== 'Unknown' && lastLoginTime) {
      try {
        const detectionTime = new Date(date);
        detection.isNew = detectionTime > lastLoginTime;
      } catch (e) {
        detection.isNew = false;
      }
    } else {
      detection.isNew = false;
    }
    return detection;
  });

  // Dummy data for Telegram Monitoring if no data exists.
  const dummyData = [
    {
      date: '2024-01-11',
      type: 'VIP Protection Alert',
      detail: 'Detected messages mentioning a high-profile individual in a monitored Telegram group.',
      extraInfo: 'Further investigation suggested that the individual was mentioned in hostile discussions.',
    },
    {
      date: '2024-01-12',
      type: 'Suspicious Activity',
      detail: 'Potential phishing attempt targeting VIP individuals identified in a monitored Telegram chat.',
      extraInfo: 'Multiple phishing messages detected in the chat. Users are advised to be cautious.',
    },
    {
      date: '2024-01-15',
      type: 'Threat Intelligence',
      detail: 'Observed hostile discussions related to VIP security in a monitored Telegram channel.',
      extraInfo: 'Security measures are recommended as a precaution.',
    },
  ];


  // Use dummyData if no findings are available.
  const tableData = standardizedFindings.length === 0 ? dummyData : standardizedFindings;

  return (
    <>
      {/* Recent Events */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <SectionTitle
              text="Recent Events & Detections"
              tooltip="Latest events and detections across the platform."
            />
            <RecentCVE detections={data.detections || []} />
          </Box>
        </Grid>
      </Grid>

      {/* VIP Profiles (only if subscription is enterprise) */}
      {data.subscriptionType &&
      data.subscriptionType.toLowerCase() === "enterprise" ? (
        data.vipInfo && data.vipInfo.length > 0 ? (
          <VipCardsContainer>
            <Typography variant="h6" color="white" gutterBottom>
              VIP Profiles
            </Typography>
            <HorizontalScroll>
              {data.vipInfo.map((vip) => renderVipCard(vip, onVipClick))}
            </HorizontalScroll>
          </VipCardsContainer>
        ) : (
          <Box textAlign="center" color="white" mt={4} mb={2}>
            <Typography variant="h6" gutterBottom>
              No VIP data
            </Typography>
            <CircularProgress />
          </Box>
        )
      ) : null}
<br></br>
      {/* Telegram Monitoring */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <SectionTitle
              text="Telegram Monitoring"
              tooltip="Monitoring Telegram for VIP protection and other security events."
            />
            <Box sx={{ overflowX: 'auto' }}>
              <FindingsTable findings={tableData} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* HeatMap */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <HeatMap />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

// ====== Main Dashboard Component ====== //
export default function Dashboard({ data, loading }) {
  const theme = useTheme();

  // AI Chat states
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Hello! How can I help you today?' }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [loadingAI, setLoadingAI] = useState(false);

  // VIP modal and deletion/snackbar states
  const [selectedVip, setSelectedVip] = useState(null);
  const [deleteVipLoading, setDeleteVipLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleVipCardClick = (vip) => {
    setSelectedVip(vip);
  };
  const closeSelectedVipModal = () => {
    setSelectedVip(null);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle VIP deletion
  const handleDeleteVip = async () => {
    try {
      setDeleteVipLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user || !selectedVip) throw new Error("User or VIP not found");
      const token = await user.getIdToken(true);
      await axios.delete(`https://ln2jj4k2-3005.euw.devtunnels.ms/api/vip/${selectedVip.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackbar({ open: true, message: "VIP deleted successfully.", severity: "success" });
      closeSelectedVipModal();
    } catch (error) {
      console.error("Error deleting VIP:", error);
      const errorMsg = error.response?.data?.message || "Failed to delete VIP";
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    } finally {
      setDeleteVipLoading(false);
    }
  };

  const handleInputChange = (value) => setInputValue(value);
  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    const userMessage = { role: 'user', content: inputValue.trim() };
    setMessages((prev) => [...prev, userMessage]);
    setLoadingAI(true);
    setTimeout(() => {
      const aiResponse = { role: 'assistant', content: 'This is an AI response.' };
      setMessages((prev) => [...prev, aiResponse]);
      setLoadingAI(false);
    }, 1000);
    setInputValue('');
  };

  // Drawer & Dialog for AI Assistant
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDrawer = (open) => () => setIsDrawerOpen(open);
  const openDialog = () => {
    setIsDrawerOpen(false);
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }
  if (!data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h5" color="white">
          No data available at the moment. Please check back later.
        </Typography>
      </Box>
    );
  }

  return (
    <MainContent>
      {/* Overview / Header Cards */}
      <DashboardHeaderSection data={data} />

      {/* Dashboard Sections */}
      <DashboardSections data={data} onVipClick={handleVipCardClick} />

      {/* Floating Action Button for AI Assistant */}
      <Fab
        color="primary"
        aria-label="chat"
        onClick={toggleDrawer(true)}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#333',
          },
        }}
      >
        <ChatIcon sx={{ color: '#fff' }} />
      </Fab>

      {/* Drawer with AI Assistant */}
      <Drawer
        anchor="right"
        open={isDrawerOpen && !isDialogOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '600px' },
            backgroundColor: '#1a1a1a',
            color: '#fff',
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            boxShadow: 'none',
          },
        }}
      >
        <Box role="presentation" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{
              borderBottom: '1px solid #333',
              borderTopLeftRadius: '20px',
            }}
          >
            <Typography variant="h6">AI Assistant</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Tooltip title="Expand to larger view" arrow>
                <IconButton onClick={openDialog} sx={{ color: '#fff' }}>
                  <FullscreenIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close" arrow>
                <IconButton onClick={toggleDrawer(false)} sx={{ color: 'grey' }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box flexGrow={1} p={2} overflow="auto">
            <ThreatFeed
              userData={data}
              messages={messages}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onSendMessage={handleSendMessage}
              loading={loadingAI}
            />
          </Box>
        </Box>
      </Drawer>

      {/* Dialog for expanded AI Assistant */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            color: '#fff',
            borderRadius: '20px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">AI Assistant (Expanded View)</Typography>
          <IconButton
            onClick={closeDialog}
            size="small"
            disableRipple
            disableFocusRipple
            sx={{
              p: '4px',
              color: 'grey',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#fff',
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '70vh' }}>
          <ThreatFeed
            userData={data}
            messages={messages}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onSendMessage={handleSendMessage}
            loading={loadingAI}
          />
        </DialogContent>
      </Dialog>

      {/* Full-Screen Modal for VIP Details */}
      <Dialog
        open={!!selectedVip}
        onClose={closeSelectedVipModal}
        fullScreen
        BackdropProps={{
          style: {
            backdropFilter: 'blur(6px)',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            boxShadow: 'none',
            borderRadius: 8,
          },
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <DialogTitle sx={{ color: '#fff', m: 0, p: 2 }}>
            {selectedVip?.firstName} {selectedVip?.lastName}
          </DialogTitle>
          <IconButton
            onClick={closeSelectedVipModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {selectedVip && (
          <DialogContent sx={{ color: '#fff' }}>
            {renderVipModalContent(selectedVip, data)}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={handleDeleteVip}
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            disabled={deleteVipLoading}
          >
            {deleteVipLoading ? <CircularProgress size={20} color="inherit" /> : "Delete VIP"}
          </Button>
          <Button onClick={closeSelectedVipModal} sx={{ color: '#fff' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MainContent>
  );
}
