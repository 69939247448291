import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { red, yellow, green } from '@mui/material/colors';
import { parseISO, differenceInMonths, format } from 'date-fns';
import { getAuth } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  maxHeight: 400,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
  overflow: 'auto',
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-track': { background: '#1E1E1E' },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#555',
    borderRadius: '4px',
    border: '2px solid #1E1E1E',
  },
  scrollbarWidth: 'thin',
  scrollbarColor: '#555 #1E1E1E',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '60px',
  transition: 'background-color 0.3s, transform 0.3s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#2C2C2E',
    transform: 'scale(1.01)',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#FFFFFF',
  padding: theme.spacing(1.5),
  borderBottom: 'none',
}));

const TruncatedContentCell = styled(StyledTableCell)(({ theme }) => ({
  maxWidth: 200,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const ActionCell = styled(StyledTableCell)(({ theme }) => ({
  minWidth: 120,
  textAlign: 'center',
}));

const GlassDialogContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(40, 40, 40, 0.95)',
  backdropFilter: 'blur(8px)',
  borderRadius: '15px',
  padding: theme.spacing(3),
  maxWidth: '90%',
  maxHeight: '90%',
  overflowY: 'auto',
  color: '#fff',
  position: 'relative',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: '#FFFFFF',
}));

const SeverityChip = styled(Chip)(({ severity, theme }) => ({
  backgroundColor: severity,
  color: '#FFFFFF',
  fontWeight: 'bold',
}));

const RecentCVE = ({ detections }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [insights, setInsights] = useState(null);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [insightsError, setInsightsError] = useState(null);
  const [solvedGroupIds, setSolvedGroupIds] = useState([]);
  const [solvingEventId, setSolvingEventId] = useState(null);

  const auth = getAuth();

  // Check if a given date is within the last 3 months.
  const isWithinLastThreeMonths = (dateStr) => {
    try {
      const eventDate = parseISO(dateStr);
      const currentDate = new Date();
      const monthsDifference = differenceInMonths(currentDate, eventDate);
      return monthsDifference < 3;
    } catch (error) {
      console.error(`Invalid date format: ${dateStr}`, error);
      return false;
    }
  };

  // Map severity text to a numerical level.
  const getSeverityLevel = (severity) => {
    switch (severity) {
      case 'Low': return 1;
      case 'Medium': return 2;
      case 'High': return 3;
      case 'Critical': return 4;
      default: return 0;
    }
  };

  // Adjust severity based on event details and recency.
  const adjustSeverity = (group) => {
    const hasCompromisedCredential = group.events.some(event => event.email || event.content);
    if (hasCompromisedCredential) {
      return isWithinLastThreeMonths(group.date) ? 'High' : 'Medium';
    }
    return group.severity || 'Low';
  };

  // Group detections into events using channel_username and date.
  // Event id is built as: "<channel_username>_<date>"
  const groupDetections = (detections) => {
    const groups = {};
    detections.forEach(event => {
      if (!event.channel_username || !event.date) {
        console.warn('Event missing channel_username or date:', event);
        return;
      }
      const key = event.id && event.id.startsWith('Dummy-')
        ? event.id
        : `${event.channel_username}_${event.date}`;
      if (!groups[key]) {
        groups[key] = {
          id: key,
          channel_username: event.channel_username,
          date: event.date,
          events: [event],
          severity: adjustSeverity({ ...event, events: [event] }),
        };
      } else {
        groups[key].events.push(event);
        const currentSeverityLevel = getSeverityLevel(groups[key].severity);
        const eventSeverity = adjustSeverity(groups[key]);
        if (getSeverityLevel(eventSeverity) > currentSeverityLevel) {
          groups[key].severity = eventSeverity;
        }
      }
    });
    return Object.values(groups);
  };

  // Use dummy data if no detections provided.
  const dummyData = [
    {
      id: 'Dummy-001',
      severity: 'Medium',
      channel_username: 'System Placeholder',
      date: '2024-07-15T00:00:00Z',
      email: 'user1@example.com',
      content: 'Simulated compromised credential event within the last 3 months.',
      recommendedActions: 'Reset passwords and monitor account activity.',
      events: [
        { id: 'det1', content: 'Message content 1' },
        { id: 'det2', content: 'Message content 2' }
      ]
    },
  ];

  const displayData = detections && detections.length > 0
    ? groupDetections(detections)
    : groupDetections(dummyData);

  const formatDate = (dateStr) => {
    try {
      const date = parseISO(dateStr);
      return format(date, 'MMMM d, yyyy');
    } catch (error) {
      console.error(`Error formatting date: ${dateStr}`, error);
      return dateStr;
    }
  };

  const generateEventName = (group) => {
    const channel = group.channel_username;
    const dateFormatted = formatDate(group.date);
    return `${channel} - ${dateFormatted}`;
  };

  // Fetch AI insights for an event.
  const fetchInsights = async (group) => {
    setLoadingInsights(true);
    setInsightsError(null);
    setInsights(null);

    const aggregatedContent = group.events
      .map(event =>
        typeof event.content === 'string'
          ? event.content
          : event.content ? JSON.stringify(event.content) : 'No content provided'
      )
      .join(' ');
    const aggregatedEmails =
      group.events
        .map(event =>
          typeof event.email === 'string'
            ? event.email
            : event.email ? JSON.stringify(event.email) : null
        )
        .filter(email => email)
        .join(', ') || 'No email provided';

    if (group.id.startsWith('Dummy-')) {
      const demoInsights = {
        headline: 'Compromised Credential Event Detected',
        date: group.date,
        source: group.channel_username,
        type: 'Credential Compromise',
        detail: "A compromised credential event was detected involving the email 'user1@example.com'.",
        threatLevel: 'Medium',
        executiveSummary: "Potential security threat detected. Immediate action is advised.",
        actionableSteps: {
          step1: 'Reset the password immediately.',
          step2: 'Notify the IT team.',
          step3: 'Enable multi-factor authentication.',
          step4: 'Monitor account activity.',
        },
        additionalDetails: {
          description: 'Simulated event for demonstration purposes.',
        },
        email: aggregatedEmails,
        content: aggregatedContent,
      };
      setInsights(demoInsights);
      setLoadingInsights(false);
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');
      const token = await user.getIdToken(true);
      const threatData = {
        id: group.id,
        description: aggregatedContent,
        channel_username: group.channel_username,
        date: group.date,
        severity: group.severity,
        email: aggregatedEmails,
        content: aggregatedContent,
      };
      const response = await fetch('https://ln2jj4k2-3005.euw.devtunnels.ms/api/generate-insights', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ threatData, threatId: group.id }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to fetch insights.');
      }
      const data = await response.json();
      const insightsData = data.insights || {};
      insightsData.actionableSteps =
        Array.isArray(insightsData.actionableSteps)
          ? insightsData.actionableSteps.map(step =>
              typeof step === 'object' ? JSON.stringify(step) : step
            )
          : insightsData.actionableSteps
          ? [typeof insightsData.actionableSteps === 'object' ? JSON.stringify(insightsData.actionableSteps) : insightsData.actionableSteps]
          : [];
      setInsights(insightsData);
    } catch (error) {
      console.error('Error fetching insights:', error);
      setInsightsError(error.message || 'An unexpected error occurred.');
      setInsights({});
    } finally {
      setLoadingInsights(false);
    }
  };

  const handleRowClick = (group) => {
    setSelectedEvent(group);
    fetchInsights(group);
  };

  // Mark as solved: send groupId and userId to the backend.
  const handleMarkAsSolved = async (groupId, e) => {
    e.stopPropagation();
    setSolvingEventId(groupId);
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');
      const token = await user.getIdToken(true);
      console.log("Marking group:", groupId);
      await axios.post(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/api/mark-solved',
        { groupId, userId: user.uid },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSolvedGroupIds(prev => [...prev, groupId]);
    } catch (error) {
      console.error('Error marking as solved:', error);
    } finally {
      setSolvingEventId(null);
    }
  };

  const handleClose = () => {
    setSelectedEvent(null);
    setInsights(null);
    setInsightsError(null);
    setLoadingInsights(false);
  };

  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'Critical': return red[600];
      case 'High': return red[300];
      case 'Medium': return yellow[700];
      case 'Low': return green[500];
      default: return 'grey';
    }
  };

  const renderField = (label, value) => (
    <Typography variant="body2" sx={{ color: '#FFFFFF', mt: 1 }}>
      <strong>{label}:</strong>{' '}
      {value !== null && value !== undefined
        ? typeof value === 'string' || typeof value === 'number'
          ? value
          : JSON.stringify(value)
        : 'N/A'}
    </Typography>
  );

  const renderDetailModal = () => {
    if (!selectedEvent) return null;
    const detailContent =
      selectedEvent.content && selectedEvent.content !== 'N/A'
        ? selectedEvent.content
        : selectedEvent.text || 'N/A';
    return (
      <Dialog
        open={Boolean(selectedEvent)}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            background: 'rgba(40, 40, 40, 0.95)',
            backdropFilter: 'blur(8px)',
            borderRadius: '15px',
            padding: 20,
            color: '#fff',
          },
        }}
      >
        <DialogTitle sx={{ color: '#fff' }}>Event Details</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
            <strong>Event Name:</strong> {generateEventName(selectedEvent)}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
            <strong>Severity:</strong>{' '}
            <SeverityChip label={selectedEvent.severity} severity={getSeverityColor(selectedEvent.severity)} size="small" />
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
            <strong>Channel Username:</strong> {selectedEvent.channel_username}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
            <strong>Date:</strong> {formatDate(selectedEvent.date)}
          </Typography>
          <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', mt: 3, mb: 2 }}>
            AI-Generated Insights
          </Typography>
          {loadingInsights ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress color="inherit" size={24} />
              <Typography variant="body2" sx={{ color: '#fff', ml: 2 }}>
                Generating insights...
              </Typography>
            </Box>
          ) : insightsError ? (
            <Typography variant="body2" sx={{ color: red[300] }}>
              Error: {insightsError}
            </Typography>
          ) : insights && Object.keys(insights).length > 0 ? (
            <Box sx={{ color: '#fff' }}>
              {renderField('Headline', insights.headline)}
              {renderField('Date', insights.date ? formatDate(insights.date) : 'N/A')}
              {renderField('Source', selectedEvent.channel_username)}
              {renderField('Type', insights.type)}
              {renderField('Detail', insights.detail)}
              {renderField('Threat Level', insights.threatLevel)}
              {renderField('Executive Summary', insights.executiveSummary)}
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                <strong>Actionable Steps:</strong>
              </Typography>
              <ul style={{ paddingLeft: '20px', color: '#fff' }}>
                {Array.isArray(insights.actionableSteps) && insights.actionableSteps.length > 0 ? (
                  insights.actionableSteps.map((step, index) => (
                    <li key={index}>{typeof step === 'object' ? JSON.stringify(step) : step}</li>
                  ))
                ) : (
                  <li>N/A</li>
                )}
              </ul>
              {insights.additionalDetails && typeof insights.additionalDetails === 'object' ? (
                <>
                  <Typography variant="subtitle1">
                    <strong>Additional Details:</strong>
                  </Typography>
                  {Object.entries(insights.additionalDetails).map(([key, value]) => (
                    <React.Fragment key={key}>
                      {renderField(key.charAt(0).toUpperCase() + key.slice(1), typeof value === 'object' ? JSON.stringify(value) : value)}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                renderField('Additional Details', insights.additionalDetails)
              )}
              {insights.email && renderField('Email', insights.email)}
              {insights.content && renderField('Content', insights.content)}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ color: '#fff' }}>
              No insights available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" fullWidth>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ mt: 3, px: 2 }}>
      <StyledTableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Event Name</StyledTableCell>
              <StyledTableCell>Severity</StyledTableCell>
              <StyledTableCell>Channel Username</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <TruncatedContentCell>Content</TruncatedContentCell>
              <StyledTableCell>Count</StyledTableCell>
              <ActionCell>Action</ActionCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((group) => {
              const eventIsSolved =
                solvedGroupIds.includes(group.id) ||
                group.events.every(e => e.solved === true);
              return (
                <StyledTableRow key={group.id} onClick={() => handleRowClick(group)}>
                  <StyledTableCell>{generateEventName(group)}</StyledTableCell>
                  <StyledTableCell>
                    <SeverityChip label={group.severity} severity={getSeverityColor(group.severity)} />
                  </StyledTableCell>
                  <StyledTableCell>{group.channel_username}</StyledTableCell>
                  <StyledTableCell>{formatDate(group.date)}</StyledTableCell>
                  <TruncatedContentCell>
                    <Typography variant="body2" sx={{ color: '#FFFFFF', display: 'inline' }}>
                      {group.events.map(event => {
                        const content =
                          typeof event.content === 'string'
                            ? event.content
                            : event.content ? JSON.stringify(event.content) : '';
                        return content;
                      }).join(' | ')}
                    </Typography>
                  </TruncatedContentCell>
                  <StyledTableCell>{group.events.length}</StyledTableCell>
                  <ActionCell>
                    {eventIsSolved ? (
                      <Chip label="Solved" color="success" size="small" />
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => handleMarkAsSolved(group.id, e)}
                        disabled={solvingEventId === group.id}
                      >
                        {solvingEventId === group.id ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : (
                          "Mark as Solved"
                        )}
                      </Button>
                    )}
                  </ActionCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {renderDetailModal()}
    </Box>
  );
};

export default RecentCVE;
