import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Paper, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = ({ setIsAuthenticated }) => {
  const [isResetMode, setIsResetMode] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetMessage, setResetMessage] = useState("");

  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoginError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsAuthenticated(true);
      navigate("/");
    } catch (error) {
      setLoginError("Failed to login. Please check your credentials.");
      console.error("Login error:", error);
    }
  };

  const handleResetPassword = async () => {
    const emailToReset = resetEmail || email;
    if (!emailToReset) {
      setResetError("Please enter your email address for password reset.");
      return;
    }
    try {
      setResetError("");
      setResetMessage("");
      await axios.post("https://ln2jj4k2-3005.euw.devtunnels.ms/api/reset-password", { email: emailToReset });
      setResetMessage("Password reset email sent.");
    } catch (error) {
      console.error("Error resetting password:", error);
      setResetError("Failed to reset password.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        background: "linear-gradient(135deg, #ffffff, #f2f2f7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 3,
          width: "100%",
          maxWidth: 400,
          backgroundColor: "#fff",
          textAlign: "center",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        {isResetMode ? (
          // Reset Password Form
          <>
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: "bold", mb: 3, color: "#000" }}
            >
              Reset My Password
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="reset-email"
              label="Email Address"
              name="reset-email"
              autoComplete="email"
              autoFocus
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "& fieldset": { borderColor: "#ccc" },
                  "&:hover fieldset": { borderColor: "#ccc" },
                  "&.Mui-focused fieldset": { borderColor: "#ccc" },
                  color: "#000",
                },
                "& .MuiInputBase-input::placeholder": { color: "#aaa" },
              }}
            />
            {resetError && (
              <Typography variant="body2" sx={{ color: "#d0021b", mt: 1 }}>
                {resetError}
              </Typography>
            )}
            {resetMessage && (
              <Typography variant="body2" sx={{ color: "#0071E3", mt: 1 }}>
                {resetMessage}
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              onClick={handleResetPassword}
              sx={{
                mt: 1,
                py: 1.5,
                backgroundColor: "#0071E3",
                textTransform: "none",
                fontSize: "1rem",
                borderRadius: "12px",
                "&:hover": { backgroundColor: "#005bb5" },
              }}
            >
              Send Reset Link
            </Button>
            <Typography
              variant="body2"
              sx={{ color: "#0071E3", mt: 2, cursor: "pointer" }}
              onClick={() => setIsResetMode(false)}
            >
              Back to Sign In
            </Typography>
          </>
        ) : (
          // Login Form
          <>
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: "bold", mb: 3, color: "#000" }}
            >
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    "& fieldset": { borderColor: "#ccc" },
                    "&:hover fieldset": { borderColor: "#ccc" },
                    "&.Mui-focused fieldset": { borderColor: "#ccc" },
                    color: "#000",
                  },
                  "& .MuiInputBase-input::placeholder": { color: "#aaa" },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    "& fieldset": { borderColor: "#ccc" },
                    "&:hover fieldset": { borderColor: "#ccc" },
                    "&.Mui-focused fieldset": { borderColor: "#ccc" },
                    color: "#000",
                  },
                  "& .MuiInputBase-input::placeholder": { color: "#aaa" },
                }}
              />
              {loginError && (
                <Typography variant="body2" sx={{ color: "#d0021b", mt: 1 }}>
                  {loginError}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  backgroundColor: "#0071E3",
                  textTransform: "none",
                  fontSize: "1rem",
                  boxShadow: "0px 4px 12px rgba(0,113,227,0.3)",
                  borderRadius: "12px",
                  "&:hover": { backgroundColor: "#005bb5" },
                }}
              >
                Sign In
              </Button>
              <Typography variant="body2" sx={{ color: "#000", mt: 2 }}>
                Welcome back. Please enter your credentials.
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#0071E3", mt: 2, cursor: "pointer" }}
                onClick={() => setIsResetMode(true)}
              >
                Forgot Password?
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default Login;
