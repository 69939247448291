// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDrCHk6bFUXYuE30-5kDvlOrbKWZbIebts",
  authDomain: "pardx-275de.firebaseapp.com",
  projectId: "pardx-275de",
  storageBucket: "pardx-275de.appspot.com",
  messagingSenderId: "594635262555",
  appId: "1:594635262555:web:720905400193861abbcd7e",
  measurementId: "G-SD4SLJ2B76"
};

const firebaseApp = initializeApp(firebaseConfig);
export { firebaseApp };
