import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Signup.css';

function Signup() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    fullName: '',
    phoneNumber: '',
    companyDomain: '',
    region: '',
    industry: '',
    plan: 'essential',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // When signup succeeds, show the welcome view
  const [showWelcome, setShowWelcome] = useState(false);

  const plans = [
    { value: 'essential', label: 'Essential' },
    { value: 'business', label: 'Business' },
    { value: 'enterprise', label: 'Enterprise' },
  ];

  // Import Bruno Ace font from Google Fonts
  useEffect(() => {
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Basic client-side validation
    if (
      !formData.email ||
      !formData.password ||
      !formData.fullName ||
      !formData.phoneNumber ||
      !formData.companyDomain ||
      !formData.region ||
      !formData.industry
    ) {
      setLoading(false);
      return setError('Please fill in all required fields.');
    }

    try {
      // POST signup request to your backend
      await axios.post(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/signup',
        formData
      );
      setLoading(false);
      // Show welcome view instead of navigating to a separate route
      setShowWelcome(true);
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.error || 'Something went wrong. Please try again.');
    }
  };

  // When welcome view is shown, wait 5 seconds then redirect to login
  useEffect(() => {
    if (showWelcome) {
      const timer = setTimeout(() => {
        window.location.href = '/login';
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showWelcome]);

  // Shared logo style
  const logoStyle = {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '1rem',
    color: 'black',
    fontFamily: 'Bruno Ace, sans-serif',
  };

  // If welcome view is active, render it instead of the signup form
  if (showWelcome) {
    return (
      <div className="welcome-container">
        <div className="welcome-message">
          {/* Text-based logo */}
          <div className="welcome-logo" style={logoStyle}>
            pardx
          </div>
          <p className="verification-note">
            We’ve sent you a verification email. Please check your inbox to verify your email and activate your account.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="signup-container">
      <div className="signup-box">
        {/* Text-based logo at the top of the form */}
        <div className="signup-logo" style={logoStyle}>
          pardx
        </div>
        <h1 className="signup-title">Create Your Account</h1>
        <p className="signup-subtitle">
          Turning Vision Into Reality, Building the Future Others Imagine.
        </p>
        <form className="signup-form" onSubmit={handleSubmit}>
          <label className="input-label" htmlFor="fullName">Full Name</label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="John Appleseed"
            value={formData.fullName}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="john@example.com"
            value={formData.email}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="••••••••"
            value={formData.password}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="+1 234 567 890"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="companyDomain">Company Domain</label>
          <input
            type="text"
            name="companyDomain"
            id="companyDomain"
            placeholder="example.com"
            value={formData.companyDomain}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="region">Region</label>
          <input
            type="text"
            name="region"
            id="region"
            placeholder="e.g., USA"
            value={formData.region}
            onChange={handleChange}
            className="input-field"
          />

          <label className="input-label" htmlFor="industry">Industry</label>
          <input
            type="text"
            name="industry"
            id="industry"
            placeholder="e.g., Technology"
            value={formData.industry}
            onChange={handleChange}
            className="input-field"
          />

          <div className="plans-container">
            <p className="plan-label">Choose Your Plan:</p>
            <div className="plan-options">
              {plans.map((p) => (
                <label key={p.value} className={`plan-card ${formData.plan === p.value ? 'plan-selected' : ''}`}>
                  <input
                    type="radio"
                    name="plan"
                    value={p.value}
                    checked={formData.plan === p.value}
                    onChange={handleChange}
                    className="plan-radio"
                  />
                  <span className="plan-title">{p.label}</span>
                </label>
              ))}
            </div>
          </div>

          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Creating Account...' : 'Sign Up'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Signup;
