import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { drawerWidth } from "../constants";
import defaultProfile from "../assets/defualt.jpg"; // Use your real image path

// Helper: Mask a credit card number to display only last 4 digits
const maskCreditCard = (card) => {
  if (!card) return "";
  const trimmed = card.toString().replace(/\D/g, "");
  return trimmed.length > 4 ? "•••• " + trimmed.slice(-4) : trimmed;
};

// Styled element for displaying a credit card number
const CreditCardBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  color: "#000",
  borderRadius: 4,
  padding: theme.spacing(0.5, 1),
  display: "inline-block",
  fontFamily: "monospace",
  fontSize: "0.9rem",
  marginRight: theme.spacing(1),
}));

// Main container
const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  minHeight: "100vh",
  backgroundColor: "#000",
  color: "#fff",
  padding: theme.spacing(4),
  marginLeft: drawerWidth,
}));

// Header text
const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 600,
  color: "#fff",
}));

// Styled TextField for read-only fields
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    backgroundColor: "transparent",
    "& fieldset": {
      borderColor: "#333333",
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: "#555",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0071E3",
      borderWidth: 1,
    },
    "&.Mui-focused": {
      outline: "none",
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#fff",
  },
  "& .MuiInputLabel-root": {
    color: "#ccc",
  },
  "& input::placeholder": {
    color: "#666",
  },
}));

// Styled Save Button
const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#000",
  textTransform: "none",
  borderRadius: 8,
  fontSize: "1rem",
  padding: theme.spacing(1.5),
  maxWidth: 200,
  width: "100%",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
}));

// Styled Red Button
const RedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff5252",
  color: "#fff",
  textTransform: "none",
  borderRadius: 8,
  fontSize: "1rem",
  padding: theme.spacing(1.5),
  maxWidth: 200,
  width: "100%",
  "&:hover": {
    backgroundColor: "#e60000",
  },
}));

// DynamicFieldRow for dynamic inputs
const DynamicFieldRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

// VipFormCard for the Add VIP dialog
const VipFormCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: 16,
  padding: theme.spacing(3),
  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

// VipTextField for VIP form input
const VipTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#ccc",
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0071E3",
      borderWidth: 1,
    },
    "&.Mui-focused": {
      outline: "none",
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#000",
  },
  "& .MuiInputLabel-root": {
    color: "#000",
  },
  "& input::placeholder": {
    color: "#777",
  },
}));

// Utility: Given a URL, return a social media label.
const getSocialMediaLabel = (url) => {
  const domain = new URL(url.includes("http") ? url : `http://${url}`).hostname;
  if (domain.includes("facebook")) return "Facebook";
  if (domain.includes("instagram")) return "Instagram";
  if (domain.includes("snapchat")) return "Snapchat";
  if (domain.includes("youtube")) return "YouTube";
  if (domain.includes("twitter") || domain.includes("x.")) return "Twitter";
  if (domain.includes("flickr")) return "Flickr";
  return domain;
};

// Render VIP card with a circular image (smaller) on top and full name centered.
// Card now is taller (minHeight: 260) so it's not a square.
const renderVipCard = (vip, handleClick) => (
  <Card
    key={vip.id}
    onClick={() => handleClick(vip)}
    sx={{
      minWidth: 220,
      minHeight: 260,
      borderRadius: 4,
      backgroundColor: "#fff",
      color: "#000",
      cursor: "pointer",
      flex: "0 0 auto",
      textAlign: "center",
      p: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <CardMedia
      component="img"
      image={defaultProfile}
      alt="Profile"
      sx={{
        width: 80,
        height: 80,
        borderRadius: "50%",
        mx: "auto",
        mb: 1,
        objectFit: "cover",
      }}
    />
    <CardContent sx={{ p: 0 }}>
      <Typography variant="h6">
        {vip.firstName} {vip.lastName}
      </Typography>
    </CardContent>
  </Card>
);

// Render VIP full-screen modal content with organized sections.
const renderVipModalContent = (selectedVip, userData) => {
  if (!selectedVip) return null;

  // Filter detections for the selected VIP.
  const vipDetections = userData?.vipDetections
    ? userData.vipDetections.filter(
        (d) =>
          d.vipFullName &&
          d.vipFullName.toLowerCase() ===
            (selectedVip.firstName + " " + selectedVip.lastName).toLowerCase()
      )
    : [];

  // Gather social media buttons from the filtered detections.
  const socialMediaButtons = [];
  vipDetections.forEach((det) => {
    if (det.docData && Array.isArray(det.docData.urls)) {
      det.docData.urls.forEach((url) => {
        if (!socialMediaButtons.some((btn) => btn.url === url)) {
          socialMediaButtons.push({ url, label: getSocialMediaLabel(url) });
        }
      });
    }
  });

  // Extract credentials from detection emails containing ":"
  const credentialDetails = [];
  vipDetections.forEach((det) => {
    if (det.docData && Array.isArray(det.docData.emails)) {
      det.docData.emails.forEach((entry) => {
        if (entry.includes(":")) {
          const [email, password] = entry.split(":");
          credentialDetails.push({ email, password });
        }
      });
    }
  });

  return (
    <Box sx={{ p: 3, color: "#fff" }}>
      {/* Header with profile image and full name */}
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <Box
          component="img"
          src={defaultProfile}
          alt="Profile"
          sx={{
            width: 120,
            height: 120,
            borderRadius: "50%",
            mb: 1,
            objectFit: "cover",
          }}
        />
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {selectedVip.firstName} {selectedVip.lastName}
        </Typography>
      </Box>

      {/* Credentials Section */}
      {credentialDetails.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
            Credentials
          </Typography>
          {credentialDetails.map((cred, idx) => (
            <Box key={idx} sx={{ mt: 1 }}>
              <Typography variant="body1">
                <strong>Email:</strong> {cred.email}
              </Typography>
              <Typography variant="body1">
                <strong>Password:</strong> {cred.password}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {/* Social Media Section */}
      {socialMediaButtons.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
            Social Media
          </Typography>
          <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap" }}>
            {socialMediaButtons.map((btn, idx) => (
              <Button
                key={idx}
                variant="contained"
                sx={{ m: 0.5, textTransform: "none" }}
                onClick={() => window.open(btn.url, "_blank")}
              >
                {btn.label}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      {/* Profile Data Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
          Profile Data
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Emails:</strong> {selectedVip.emails?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Phones:</strong> {selectedVip.phoneNumbers?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Credit Cards:</strong>{" "}
          {selectedVip.creditCardNumbers && selectedVip.creditCardNumbers.length > 0 ? (
            selectedVip.creditCardNumbers.map((card, idx) => (
              <CreditCardBox key={idx}>{maskCreditCard(card)}</CreditCardBox>
            ))
          ) : (
            "None"
          )}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>IBANs:</strong> {selectedVip.ibanNumbers?.join(", ") || "None"}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Crypto Wallets:</strong> {selectedVip.cryptoWalletAddresses?.join(", ") || "None"}
        </Typography>
      </Box>

      {/* Detections Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ borderBottom: "1px solid #fff", pb: 1 }}>
          Detections
        </Typography>
        {vipDetections.length === 0 ? (
          <Typography>No detections for this VIP.</Typography>
        ) : (
          vipDetections.map((det, idx) => (
            <Box
              key={idx}
              sx={{ mb: 2, p: 2, border: "1px solid #ddd", borderRadius: 2 }}
            >
              <Typography variant="subtitle1">
                Detection Type: {det.detectionType}
              </Typography>
              <Typography variant="body2">
                Date: {new Date(det.detectionDate).toLocaleString()}
              </Typography>
              {det.docData && Array.isArray(det.docData.urls) && det.docData.urls.length > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    URLs:
                  </Typography>
                  {det.docData.urls.map((url, uIdx) => (
                    <Typography variant="body2" key={uIdx}>
                      {url}
                    </Typography>
                  ))}
                </Box>
              )}
              {det.docData && det.docData.content && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Content snippet:
                  </Typography>
                  <Typography variant="body2">
                    {det.docData.content}
                  </Typography>
                </Box>
              )}
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

function SettingsPage() {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportFrequency, setReportFrequency] = useState("");
  const [domains, setDomains] = useState([""]);
  const [subscriptionLevel, setSubscriptionLevel] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // VIP Dialog state (Add VIP)
  const [vipDialogOpen, setVipDialogOpen] = useState(false);
  const [vipFirstName, setVipFirstName] = useState("");
  const [vipLastName, setVipLastName] = useState("");
  const [vipPhones, setVipPhones] = useState([""]);
  const [vipEmails, setVipEmails] = useState([""]);
  const [vipCreditCards, setVipCreditCards] = useState([""]);
  const [vipIbans, setVipIbans] = useState([""]);
  const [vipCryptoWallets, setVipCryptoWallets] = useState([""]);
  const [vipLoading, setVipLoading] = useState(false);

  // New state for delete VIP loading
  const [deleteVipLoading, setDeleteVipLoading] = useState(false);

  // Selected VIP for full-screen modal
  const [selectedVip, setSelectedVip] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (!user) {
        setLoading(false);
        return;
      }
      try {
        const docRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(docRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          let newUserData = {
            ...data,
            reportFrequency: data.reportFrequency || "monthly",
            domains: data.domains || [""],
            subscriptionType: data.subscriptionType || "essential",
          };

          // Fetch VIP data from "VIP" subcollection
          const vipSnapshot = await getDocs(collection(db, "users", user.uid, "VIP"));
          const vipData = vipSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          newUserData.vipInfo = vipData;

          // Fetch detections from "vipDetections" subcollection
          const detectionSnapshot = await getDocs(collection(db, "users", user.uid, "vipDetections"));
          const detectionData = detectionSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          newUserData.vipDetections = detectionData;

          setUserData(newUserData);
          setReportFrequency(newUserData.reportFrequency);
          setDomains(newUserData.domains);
          setSubscriptionLevel(newUserData.subscriptionType);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      setLoading(false);
    };
    fetchUserData();
  }, [auth, db]);

  const handleSaveSettings = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await setDoc(doc(db, "users", user.uid), { reportFrequency, domains }, { merge: true });
        setSnackbar({ open: true, message: "Settings updated successfully", severity: "success" });
      } catch (error) {
        console.error("Error saving settings:", error);
        setSnackbar({ open: true, message: "Failed to update settings", severity: "error" });
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleResetPassword = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      await axios.post("/api/reset-password", { email: user.email });
      setSnackbar({ open: true, message: "Password reset email sent.", severity: "success" });
    } catch (error) {
      console.error("Error resetting password:", error);
      setSnackbar({ open: true, message: "Failed to reset password.", severity: "error" });
    }
  };

  const handleDeleteAccount = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const token = await user.getIdToken(true);
      await axios.post(
        "https://ln2jj4k2-3005.euw.devtunnels.ms/api/delete-account",
        { userId: user.uid },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbar({ open: true, message: "Account deleted successfully.", severity: "success" });
      navigate("/");
    } catch (error) {
      console.error("Error deleting account:", error);
      setSnackbar({ open: true, message: "Failed to delete account.", severity: "error" });
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  // VIP Dialog Handlers (Add VIP)
  const openVipDialog = () => {
    setVipDialogOpen(true);
  };
  const closeVipDialog = () => {
    setVipDialogOpen(false);
  };

  // Dynamic field helpers (limit to max 3 items)
  const updateDynamicField = (setter, index, value, currentArray) => {
    const newArray = [...currentArray];
    newArray[index] = value;
    setter(newArray);
  };
  const addDynamicField = (setter, currentArray) => {
    if (currentArray.length < 3) {
      setter([...currentArray, ""]);
    }
  };

  // Save VIP data (Add VIP)
  const handleSaveVip = async () => {
    try {
      setVipLoading(true);
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const token = await user.getIdToken(true);
      const payload = {
        firstName: vipFirstName,
        lastName: vipLastName,
        phoneNumbers: vipPhones.filter((s) => s.trim() !== ""),
        emails: vipEmails.filter((s) => s.trim() !== ""),
        creditCardNumbers: vipCreditCards.filter((s) => s.trim() !== ""),
        ibanNumbers: vipIbans.filter((s) => s.trim() !== ""),
        cryptoWalletAddresses: vipCryptoWallets.filter((s) => s.trim() !== ""),
      };

      const response = await axios.post(
        "https://ln2jj4k2-3005.euw.devtunnels.ms/api/vip",
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status >= 200 && response.status < 300) {
        setSnackbar({ open: true, message: response.data.message, severity: "success" });
        closeVipDialog();
        setVipFirstName("");
        setVipLastName("");
        setVipPhones([""]);
        setVipEmails([""]);
        setVipCreditCards([""]);
        setVipIbans([""]);
        setVipCryptoWallets([""]);
      } else {
        setSnackbar({ open: true, message: "Unexpected response from server.", severity: "error" });
      }
    } catch (error) {
      console.error("Error saving VIP data:", error);
      const errorMsg = error.response?.data?.message || "Failed to save VIP data";
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    } finally {
      setVipLoading(false);
    }
  };

  // When a VIP card is clicked, set selectedVip
  const handleVipCardClick = (vip) => {
    setSelectedVip(vip);
  };

  // closeSelectedVipModal sets selectedVip to null
  const closeSelectedVipModal = () => {
    setSelectedVip(null);
  };

  // New: Handle deleting the selected VIP, with loading animation.
  const handleDeleteVip = async () => {
    try {
      setDeleteVipLoading(true);
      const user = auth.currentUser;
      if (!user || !selectedVip) throw new Error("User or VIP not found");
      const token = await user.getIdToken(true);
      await axios.delete(`https://ln2jj4k2-3005.euw.devtunnels.ms/api/vip/${selectedVip.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackbar({ open: true, message: "VIP deleted successfully.", severity: "success" });
      // Update the UI: remove the VIP from vipInfo list.
      setUserData((prev) => ({
        ...prev,
        vipInfo: prev.vipInfo.filter((vip) => vip.id !== selectedVip.id),
      }));
      closeSelectedVipModal();
    } catch (error) {
      console.error("Error deleting VIP:", error);
      const errorMsg = error.response?.data?.message || "Failed to delete VIP";
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    } finally {
      setDeleteVipLoading(false);
    }
  };

  // Filter detections for the selected VIP
  const getDetectionsForSelectedVip = () => {
    if (!selectedVip || !userData?.vipDetections) return [];
    const fullName = (selectedVip.firstName + " " + selectedVip.lastName).toLowerCase();
    return userData.vipDetections.filter(
      (d) => d.vipFullName && d.vipFullName.toLowerCase() === fullName
    );
  };

  if (loading) {
    return (
      <Main>
        <CircularProgress />
      </Main>
    );
  }

  return (
    <Main>
      <Header variant="h4">Settings</Header>
      <Grid container spacing={2}>
        {/* Row 1: Name & Phone Number */}
        <Grid item xs={12} sm={6}>
          <StyledTextField
            label="Name"
            value={userData?.fullName || ""}
            InputProps={{ readOnly: true }}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            label="Phone Number"
            value={userData?.phoneNumber || ""}
            InputProps={{ readOnly: true }}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        {/* Row 2: Email & Company Domain */}
        <Grid item xs={12} sm={6}>
          <StyledTextField
            label="Email"
            value={userData?.email || ""}
            InputProps={{ readOnly: true }}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            label="Company Domain"
            value={userData?.companyDomain || ""}
            InputProps={{ readOnly: true }}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        {/* Report Frequency */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel sx={{ color: "#cccccc" }}>Report Frequency</InputLabel>
            <Select
              value={reportFrequency}
              onChange={(e) => setReportFrequency(e.target.value)}
              label="Report Frequency"
              sx={{
                color: "#ffffff",
                ".MuiOutlinedInput-notchedOutline": { borderColor: "#333333" },
                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#555" },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#0071E3" },
              }}
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
            <FormHelperText sx={{ color: "#cccccc" }}>
              Current selection: {reportFrequency}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* Subscription Level */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "#ffffff" }}>
            Subscription Level
          </Typography>
          <Typography variant="body1" sx={{ color: "#dddddd" }}>
            {subscriptionLevel.charAt(0).toUpperCase() + subscriptionLevel.slice(1)}
          </Typography>
        </Grid>
        {/* Save Settings and Add VIP Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <SaveButton onClick={handleSaveSettings}>Save Settings</SaveButton>
            {subscriptionLevel.toLowerCase() === "enterprise" && (
              <SaveButton onClick={openVipDialog}>Add VIP</SaveButton>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* VIP Cards Section (for enterprise subscribers) */}
      {subscriptionLevel.toLowerCase() === "enterprise" &&
        Array.isArray(userData?.vipInfo) &&
        userData.vipInfo.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: "#fff", mb: 2 }}>
              VIP Profiles
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                overflowX: "auto",
                pb: 2,
              }}
            >
              {userData.vipInfo.map((vip) => renderVipCard(vip, handleVipCardClick))}
            </Box>
          </Box>
        )}

      {/* Reset Password and Delete Account Buttons */}
      <Box sx={{ mt: 4, display: "flex", flexDirection: "row", gap: 2 }}>
        <SaveButton onClick={handleResetPassword}>Reset Password</SaveButton>
        <RedButton onClick={handleDeleteAccount}>Delete Account</RedButton>
      </Box>

      {/* Delete Account Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to permanently delete your account? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmDeleteAccount} variant="contained" color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      {/* VIP Protection Data Dialog (Add VIP) */}
      <Dialog
        open={vipDialogOpen}
        onClose={closeVipDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            borderRadius: 16,
          },
        }}
      >
        <DialogTitle sx={{ color: "#000" }}>VIP Protection Data</DialogTitle>
        <DialogContent dividers={false} sx={{ backgroundColor: "transparent", p: 3 }}>
          <VipFormCard sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <VipTextField
              label="First Name"
              value={vipFirstName}
              onChange={(e) => setVipFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="Enter first name"
            />
            <VipTextField
              label="Last Name"
              value={vipLastName}
              onChange={(e) => setVipLastName(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="Enter last name"
              sx={{ mt: 2 }}
            />
            {/* Phone Numbers */}
            <Typography sx={{ color: "#000", mt: 2, fontWeight: 500 }}>
              Phone Numbers (include country code):
            </Typography>
            {vipPhones.map((phone, index) => (
              <DynamicFieldRow key={`phone-${index}`}>
                <VipTextField
                  label="Phone Number"
                  value={phone}
                  onChange={(e) =>
                    updateDynamicField(setVipPhones, index, e.target.value, vipPhones)
                  }
                  variant="outlined"
                  fullWidth
                  placeholder="+1XXXXXXXXXX"
                />
                {vipPhones.length > 1 && (
                  <IconButton
                    onClick={() => setVipPhones(vipPhones.filter((_, i) => i !== index))}
                    sx={{ color: "#e60000" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                {index === vipPhones.length - 1 && (
                  <IconButton
                    onClick={() => addDynamicField(setVipPhones, vipPhones)}
                    sx={{ color: vipPhones.length < 3 ? "#0071E3" : "#999" }}
                    disabled={vipPhones.length >= 3}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </DynamicFieldRow>
            ))}

            {/* Emails */}
            <Typography sx={{ color: "#000", mt: 2, fontWeight: 500 }}>Emails:</Typography>
            {vipEmails.map((emailVal, index) => (
              <DynamicFieldRow key={`email-${index}`}>
                <VipTextField
                  label="Email"
                  value={emailVal}
                  onChange={(e) =>
                    updateDynamicField(setVipEmails, index, e.target.value, vipEmails)
                  }
                  variant="outlined"
                  fullWidth
                  placeholder="user@example.com"
                />
                {vipEmails.length > 1 && (
                  <IconButton
                    onClick={() => setVipEmails(vipEmails.filter((_, i) => i !== index))}
                    sx={{ color: "#e60000" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                {index === vipEmails.length - 1 && (
                  <IconButton
                    onClick={() => addDynamicField(setVipEmails, vipEmails)}
                    sx={{ color: vipEmails.length < 3 ? "#0071E3" : "#999" }}
                    disabled={vipEmails.length >= 3}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </DynamicFieldRow>
            ))}

            {/* Credit Cards */}
            <Typography sx={{ color: "#000", mt: 2, fontWeight: 500 }}>
              Credit Card Numbers:
            </Typography>
            {vipCreditCards.map((card, index) => (
              <DynamicFieldRow key={`cc-${index}`}>
                <VipTextField
                  label="Credit Card"
                  value={card}
                  onChange={(e) =>
                    updateDynamicField(setVipCreditCards, index, e.target.value, vipCreditCards)
                  }
                  variant="outlined"
                  fullWidth
                  placeholder="4242424242424242"
                />
                {vipCreditCards.length > 1 && (
                  <IconButton
                    onClick={() =>
                      setVipCreditCards(vipCreditCards.filter((_, i) => i !== index))
                    }
                    sx={{ color: "#e60000" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                {index === vipCreditCards.length - 1 && (
                  <IconButton
                    onClick={() => addDynamicField(setVipCreditCards, vipCreditCards)}
                    sx={{ color: vipCreditCards.length < 3 ? "#0071E3" : "#999" }}
                    disabled={vipCreditCards.length >= 3}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </DynamicFieldRow>
            ))}

            {/* IBANs */}
            <Typography sx={{ color: "#000", mt: 2, fontWeight: 500 }}>
              IBAN Numbers:
            </Typography>
            {vipIbans.map((iban, index) => (
              <DynamicFieldRow key={`iban-${index}`}>
                <VipTextField
                  label="IBAN"
                  value={iban}
                  onChange={(e) =>
                    updateDynamicField(setVipIbans, index, e.target.value, vipIbans)
                  }
                  variant="outlined"
                  fullWidth
                  placeholder="Enter IBAN"
                />
                {vipIbans.length > 1 && (
                  <IconButton
                    onClick={() => setVipIbans(vipIbans.filter((_, i) => i !== index))}
                    sx={{ color: "#e60000" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                {index === vipIbans.length - 1 && (
                  <IconButton
                    onClick={() => addDynamicField(setVipIbans, vipIbans)}
                    sx={{ color: vipIbans.length < 3 ? "#0071E3" : "#999" }}
                    disabled={vipIbans.length >= 3}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </DynamicFieldRow>
            ))}

            {/* Crypto Wallets */}
            <Typography sx={{ color: "#000", mt: 2, fontWeight: 500 }}>
              Crypto Wallet Addresses:
            </Typography>
            {vipCryptoWallets.map((wallet, index) => (
              <DynamicFieldRow key={`wallet-${index}`}>
                <VipTextField
                  label="Crypto Wallet"
                  value={wallet}
                  onChange={(e) =>
                    updateDynamicField(setVipCryptoWallets, index, e.target.value, vipCryptoWallets)
                  }
                  variant="outlined"
                  fullWidth
                  placeholder="Wallet Address"
                />
                {vipCryptoWallets.length > 1 && (
                  <IconButton
                    onClick={() =>
                      setVipCryptoWallets(vipCryptoWallets.filter((_, i) => i !== index))
                    }
                    sx={{ color: "#e60000" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                {index === vipCryptoWallets.length - 1 && (
                  <IconButton
                    onClick={() => addDynamicField(setVipCryptoWallets, vipCryptoWallets)}
                    sx={{ color: vipCryptoWallets.length < 3 ? "#0071E3" : "#999" }}
                    disabled={vipCryptoWallets.length >= 3}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </DynamicFieldRow>
            ))}
          </VipFormCard>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeVipDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSaveVip} variant="contained" color="primary" disabled={vipLoading}>
            {vipLoading ? <CircularProgress size={20} color="inherit" /> : "Save VIP Data"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full-Screen Glass Effect Modal for VIP Details */}
      <Dialog
        open={!!selectedVip}
        onClose={closeSelectedVipModal}
        fullScreen
        BackdropProps={{
          style: {
            backdropFilter: "blur(6px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            boxShadow: "none",
            borderRadius: 8,
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <DialogTitle sx={{ color: "#fff", m: 0, p: 2 }}>
            {selectedVip?.firstName} {selectedVip?.lastName}
          </DialogTitle>
          <IconButton
            onClick={closeSelectedVipModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {selectedVip && (
          <DialogContent sx={{ color: "#fff" }}>
            {renderVipModalContent(selectedVip, userData)}
          </DialogContent>
        )}
        <DialogActions>
          <Button 
            onClick={handleDeleteVip} 
            variant="contained" 
            color="error" 
            sx={{ ml: 2 }}
            disabled={deleteVipLoading}
          >
            {deleteVipLoading ? <CircularProgress size={20} color="inherit" /> : "Delete VIP"}
          </Button>
          <Button onClick={closeSelectedVipModal} sx={{ color: "#fff" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Main>
  );
}

export default SettingsPage;
