import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Page container with black background
const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  minHeight: '100vh',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

// White card styled for premium look with bigger width and portrait layout
const WhiteCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '15px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  minHeight: '350px',
  maxWidth: '400px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
  },
}));

// Styled TextField for search with white text and border
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
    '&:hover fieldset': { borderColor: '#FFD700' },
    '&.Mui-focused fieldset': { borderColor: '#FFD700' },
  },
  '& .MuiInputBase-input': {
    color: '#fff',
  },
}));

const Threat = () => {
  const [threatActors, setThreatActors] = useState([]);
  const [filteredActors, setFilteredActors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedActor, setSelectedActor] = useState(null);

  // Import Bruno Ace font dynamically inside the component
  useEffect(() => {
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  // Retrieve Firebase auth token
  const getAuthToken = useCallback(async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken(true);
    }
    return null;
  }, []);

  const fetchThreatActors = useCallback(async () => {
    try {
      const token = await getAuthToken();
      if (!token) {
        throw new Error('User not authenticated');
      }
      // Use your backend URL endpoint
      const response = await axios.get(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/api/threatactors',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Assuming data format: { threatActors: [ ... ] }
      const actors = response.data.threatActors;
      setThreatActors(actors);
      setFilteredActors(actors);
    } catch (err) {
      console.error('Error fetching threat actors:', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [getAuthToken]);

  useEffect(() => {
    fetchThreatActors();
  }, [fetchThreatActors]);

  // Filter threat actors by search query
  useEffect(() => {
    const filtered = threatActors.filter((actor) =>
      actor.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredActors(filtered);
  }, [searchQuery, threatActors]);

  if (loading) {
    return (
      <PageContainer display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" color="error">
          Failed to load threat actors. Please try again later.
        </Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography
          variant="h3"
          sx={{ color: '#fff', fontFamily: "'Bruno Ace', cursive" }}
        >
          Threat Actor Profiles
        </Typography>
        <Box width="300px">
          <StyledTextField
            variant="outlined"
            placeholder="Search by Name"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#fff' }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={6} justifyContent="center">
        {filteredActors.length > 0 ? (
          filteredActors.map((actor, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <WhiteCard onClick={() => setSelectedActor(actor)}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#000', mb: 1 }}>
                    {actor.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#333', mb: 1 }}>
                    {actor.affiliations}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#555' }} noWrap>
                    {actor.known_attacks}
                  </Typography>
                </CardContent>
              </WhiteCard>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
              <Typography variant="h6" sx={{ color: '#fff' }}>
                No threat actors found.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* Full profile dialog with glass effect */}
      <Dialog
        open={Boolean(selectedActor)}
        onClose={() => setSelectedActor(null)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            borderRadius: '15px',
          },
        }}
      >
        {selectedActor && (
          <>
            <DialogTitle sx={{ color: '#000' }}>
              {selectedActor.name}
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                <strong>Affiliations:</strong> {selectedActor.affiliations}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Known Attacks:</strong> {selectedActor.known_attacks}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>TTPs:</strong> {selectedActor.ttps}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Attribution:</strong> {selectedActor.attribution}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Targets:</strong> {selectedActor.targets}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Tools:</strong> {selectedActor.tools}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Recent Activity:</strong> {selectedActor.recent_activity}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedActor(null)} variant="contained" color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </PageContainer>
  );
};

export default Threat;
