import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Card,
  CardContent,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { firebaseApp } from '../App';

// --- Helper Functions ---
const formatDiscussionContent = (contentStr, fallback) => {
  if (!contentStr || contentStr === 'N/A') return fallback || 'N/A';
  try {
    const data = JSON.parse(contentStr);
    const name = data.n || '';
    const location = data.a || '';
    const parts = [name, location].filter(Boolean);
    return parts.length ? parts.join(' | ') : contentStr.slice(0, 100) + '...';
  } catch (err) {
    return contentStr.length > 100 ? contentStr.slice(0, 100) + '...' : contentStr;
  }
};

const formatAccountContent = (contentStr) => {
  if (!contentStr) return 'N/A';
  try {
    const data = JSON.parse(contentStr);
    const name = data.n ? `Name: ${data.n}` : '';
    const location = data.a ? `Location: ${data.a}` : '';
    const email = data.e ? `Email: ${Array.isArray(data.e) ? data.e[0] : data.e}` : '';
    const linkedin = data.linkedin ? `LinkedIn: ${data.linkedin.replace(/^https?:\/\//, '')}` : '';
    const parts = [name, location, email, linkedin].filter(Boolean);
    return parts.length ? parts.join(' | ') : contentStr.slice(0, 100) + '...';
  } catch (err) {
    return contentStr.length > 100 ? contentStr.slice(0, 100) + '...' : contentStr;
  }
};

const getContentString = (content) => {
  if (Array.isArray(content)) return content.join(', ');
  if (typeof content === 'string' && content.trim() !== '') return content;
  return 'N/A';
};

// Dynamically load "Bruno Ace" font
const useBrunoAce = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
};

// --- Styled Components ---
const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#181818',
  minHeight: '100vh',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

// White card for result preview (portrait, larger width)
const WhiteCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '15px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  minHeight: '350px',
  maxWidth: '400px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
    '&:hover fieldset': { borderColor: '#FFD700' },
    '&.Mui-focused fieldset': { borderColor: '#FFD700' },
  },
  '& .MuiInputBase-input': {
    color: '#fff',
  },
}));

// --- Main Component ---
function SearchPage() {
  useBrunoAce();

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);

  // Fetch search history on login
  useEffect(() => {
    const fetchSearchHistory = async () => {
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore(firebaseApp);
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSearchHistory(data.searchHistory || []);
        }
      }
    };
    fetchSearchHistory();
  }, [auth]);

  // Trigger search on Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (!query.trim()) {
      setError('Please enter a search term.');
      return;
    }
    setLoading(true);
    setError('');
    setResults([]);
    setOriginalResults([]);
    setHasSearched(true);
    setShowSuggestions(false);
    try {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;
      const token = await user.getIdToken();
      const response = await axios.get('https://ln2jj4k2-3005.euw.devtunnels.ms/api/search', {
        params: { q: query, userId },
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Search Results:', response.data.results);
      setResults(response.data.results);
      setOriginalResults(response.data.results);
      if (userId) {
        const db = getFirestore(firebaseApp);
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSearchHistory(data.searchHistory || []);
        }
      }
    } catch (err) {
      console.error(err);
      if (err.response) {
        setError(
          `Error: ${err.response.status} - ${
            err.response.data.message || 'An error occurred while fetching search results.'
          }`
        );
      } else if (err.request) {
        setError('Network error: Please check your internet connection.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleResultClick = (result) => {
    setSelectedResult(result);
  };

  const handleFocus = () => {
    if (!hasSearched && searchHistory.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        setShowSuggestions(false);
      }
    }, 200);
  };

  const handleSuggestionClick = (item) => {
    setQuery(item);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // CSV Export function (if needed)
  const exportCSV = () => {
    if (!results.length) return;
    const sanitizedResults = results.map(({ channel_username, partitionKey, message_id, id, ...rest }) => rest);
    const headers = Object.keys(sanitizedResults[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    sanitizedResults.forEach((obj) => {
      const row = headers.map((header) => {
        let val = obj[header] || '';
        if (typeof val === 'string') {
          val = val.replace(/"/g, '""');
          return `"${val}"`;
        }
        return val;
      });
      csvRows.push(row.join(','));
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'search_results.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // --- Group results by type using useMemo ---
  const groupedResults = useMemo(() => ({
    accounts: results.filter(r => r.partitionKey?.startsWith('email_')),
    creditCards: results.filter(r => r.partitionKey?.includes('credit_card')),
    crypto: results.filter(r => r.partitionKey?.includes('crypto_wallet')),
    iban: results.filter(r => r.partitionKey?.includes('iban')),
    ssn: results.filter(r => r.partitionKey?.includes('ssn')),
    idNumbers: results.filter(r => r.partitionKey?.includes('id_number')),
    discussions: results.filter(r => r.partitionKey?.startsWith('text_')),
    ips: results.filter(r => r.partitionKey?.startsWith('ip_')),
    phones: results.filter(r => r.partitionKey?.startsWith('phone_')),
    domains: results.filter(r => r.partitionKey?.includes('domain')),
  }), [results]);

  // Counts for each category
  const accountCount = groupedResults.accounts.length;
  const creditCardCount = groupedResults.creditCards.length;
  const cryptoCount = groupedResults.crypto.length;
  const ibanCount = groupedResults.iban.length;
  const ssnCount = groupedResults.ssn.length;
  const idNumberCount = groupedResults.idNumbers.length;
  const discussionCount = groupedResults.discussions.length;
  const ipCount = groupedResults.ips.length;
  const phoneCount = groupedResults.phones.length;
  const domainCount = groupedResults.domains.length;

  // --- Render Functions for each category ---
  const renderAccountsTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.accounts.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.email || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCreditCardsTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.creditCards.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.credit_card || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCryptoTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.crypto.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.crypto_wallet || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderIbanTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.iban.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.iban || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderSsnTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.ssn.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.ssn || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderIdNumbersTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.idNumbers.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.id_number || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDomainsTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.domains.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.domain || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>
                {row.content ? getContentString(row.content) : 'N/A'}
              </TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDiscussionsTable = () => (
    <Grid container spacing={4}>
      {groupedResults.discussions.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id || item.message_id || Math.random()}>
          <Card
            onClick={() => handleResultClick(item)}
            sx={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#2B2B2B',
              color: '#fff',
              p: 2,
              borderRadius: 2,
              '&:hover': { boxShadow: 6 },
            }}
          >
            <CardContent sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: 200 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                Hacker Discussion
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {formatDiscussionContent(item.content, item.text)}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#888', mt: 1 }}>
                Channel: t.me/******
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#888' }}>
                Date: {item.date || 'N/A'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderIPsTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.ips.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.text || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>{getContentString(row.content)}</TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>t.me/******</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderPhonesTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableBody>
          {groupedResults.phones.map((row, idx) => (
            <TableRow key={idx} hover sx={{ cursor: 'pointer' }} onClick={() => handleResultClick(row)}>
              <TableCell sx={{ color: '#fff' }}>{row.phone_number || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>{getContentString(row.content)}</TableCell>
              <TableCell sx={{ color: '#fff' }}>{row.date || 'N/A'}</TableCell>
              <TableCell sx={{ color: '#fff' }}>t.me/******</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Detailed modal for selected result with glass-effect background
  const renderDetailModal = () => {
    if (!selectedResult) return null;
    const detailContent =
      selectedResult.content && selectedResult.content !== 'N/A'
        ? selectedResult.content
        : selectedResult.text || 'N/A';

    return (
      <Modal
        open={Boolean(selectedResult)}
        onClose={() => setSelectedResult(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(8px)',
        }}
      >
        <Box
          sx={{
            bgcolor: 'rgba(40, 40, 40, 0.95)',
            p: 4,
            borderRadius: 2,
            maxWidth: '90%',
            maxHeight: '90%',
            overflowY: 'auto',
            color: '#fff',
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Detailed Result
          </Typography>
          <Typography variant="body1">{detailContent}</Typography>
          <Button
            variant="outlined"
            onClick={() => setSelectedResult(null)}
            sx={{ mt: 2, borderColor: '#fff', color: '#fff' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: hasSearched ? 'flex-start' : 'center',
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#181818',
        p: 4,
        boxSizing: 'border-box',
      }}
    >
      {/* Title */}
      {!hasSearched && (
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            mb: 4,
            fontFamily: "'Bruno Ace', cursive",
            background: 'linear-gradient(45deg, #FF6B6B, #FFD93D, #6BCB77, #4D96FF)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
          }}
        >
          Pardx Search Engine
        </Typography>
      )}

      {/* Search Bar */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: 800,
          mb: hasSearched ? 4 : 6,
          borderRadius: '50px',
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
          backgroundColor: '#282828',
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search by email, IP, phone, name, text, crypto, IBAN, credit card..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputRef={inputRef}
          fullWidth
          InputProps={{
            endAdornment: (
              <Box sx={{ pr: 1 }}>
                <Button onClick={handleSearch} sx={{ minWidth: 'auto', p: 0, color: '#fff' }}>
                  <ArrowForwardIcon />
                </Button>
              </Box>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#282828',
              borderRadius: '50px',
              '& fieldset': { border: 'none' },
              '&:hover': { boxShadow: '0px 0px 12px rgba(0,113,227,0.5)' },
              '&.Mui-focused': {
                boxShadow: '0px 0px 12px rgba(0,113,227,0.5)',
                backgroundColor: '#282828',
              },
            },
            '& input': {
              padding: '18px 20px',
              fontSize: '1.3rem',
              color: '#fff',
              backgroundColor: 'transparent',
            },
            '& input::placeholder': { color: '#888' },
          }}
        />

        {/* Suggestions Dropdown */}
        {showSuggestions && searchHistory.length > 0 && (
          <Paper
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              backgroundColor: '#282828',
              color: '#fff',
              borderRadius: '0 0 10px 10px',
              overflow: 'hidden',
              mt: '2px',
            }}
            elevation={5}
          >
            <List dense>
              {searchHistory.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={() => handleSuggestionClick(item)}
                    sx={{ color: '#fff', '&:hover': { backgroundColor: '#3c3c3c' } }}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 4, width: '100%', maxWidth: 800 }}>
          {error}
        </Alert>
      )}

      {/* Only show export button and results if user has searched */}
      {hasSearched && (
        <Box sx={{ width: '100%', maxWidth: 1400, mt: 2 }}>
          {results.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button
                variant="contained"
                size="small"
                onClick={exportCSV}
                startIcon={<FileDownloadIcon />}
                sx={{
                  width: '10%',
                  backgroundColor: '#FF9500',
                  color: '#fff',
                  textTransform: 'none',
                  borderRadius: '20px',
                  fontSize: '0.85rem',
                  '&:hover': { backgroundColor: '#FF8C00' },
                }}
              >
                Export
              </Button>
            </Box>
          )}

          {/* Tabs for all result categories */}
          <Box
            sx={{
              backgroundColor: '#1E1E1E',
              borderRadius: '8px 8px 0 0',
              px: 2,
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="inherit"
              aria-label="result type tabs"
              variant="standard"
              sx={{
                '& .MuiTab-root': {
                  minWidth: 'auto',
                  padding: '4px 8px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: '#fff',
                  fontSize: '0.75rem',
                  margin: '0 4px',
                },
                '& .MuiTabs-indicator': { backgroundColor: '#0071E3' },
              }}
            >
              <Tab label={`Accounts (${accountCount})`} />
              <Tab label={`Credit Cards (${creditCardCount})`} />
              <Tab label={`Crypto (${cryptoCount})`} />
              <Tab label={`IBAN (${ibanCount})`} />
              <Tab label={`SSN (${ssnCount})`} />
              <Tab label={`ID Numbers (${idNumberCount})`} />
              <Tab label={`Discussions (${discussionCount})`} />
              <Tab label={`IPs (${ipCount})`} />
              <Tab label={`Phones (${phoneCount})`} />
              <Tab label={`Domains (${domainCount})`} />
            </Tabs>
          </Box>

          {/* Tab Content */}
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#1E1E1E',
              p: 4,
              borderRadius: '0 0 8px 8px',
              mt: 0,
            }}
          >
            {activeTab === 0 && renderAccountsTable()}
            {activeTab === 1 && renderCreditCardsTable()}
            {activeTab === 2 && renderCryptoTable()}
            {activeTab === 3 && renderIbanTable()}
            {activeTab === 4 && renderSsnTable()}
            {activeTab === 5 && renderIdNumbersTable()}
            {activeTab === 6 && renderDiscussionsTable()}
            {activeTab === 7 && renderIPsTable()}
            {activeTab === 8 && renderPhonesTable()}
            {activeTab === 9 && renderDomainsTable()}
          </Box>
        </Box>
      )}

      {/* Detailed Modal for selected result */}
      {renderDetailModal()}
    </Box>
  );
}

export default SearchPage;
