import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Box,
    Tooltip,
    styled,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import { drawerWidth, closedDrawerWidth } from '../constants'; // Import constants
import logo from '../assets/Project_Brief_Presentation__1_-removebg-preview.png'; // Adjust path as needed

// Import the initialized Firebase app from your configuration file
import { firebaseApp } from '../firebaseConfig'; // Make sure the path is correct
import { getAuth, signOut } from 'firebase/auth';

const auth = getAuth(firebaseApp);

// Styled Drawer with Flexbox layout
const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
    width: open ? drawerWidth : closedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDrawer-paper': {
        width: open ? drawerWidth : closedDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
        }),
        boxSizing: 'border-box',
        backgroundColor: '#000000',
        color: theme.palette.text.primary,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}));

function SidebarNew({ open, handleDrawerToggle }) {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const items = [
        { icon: <DashboardIcon />, text: 'Dashboard', path: '/' },
        { icon: <SearchIcon />, text: 'Search Engine', path: '/search' },
        { icon: <AccountCircleIcon />, text: 'Payment & Billing', path: '/profile' },
        { icon: <SettingsIcon />, text: 'Settings', path: '/settings' },
        { icon: <SecurityIcon />, text: 'Threat Actors', path: '/actors' },
    ];

    // Logout handler – sign the user out and navigate to login
    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <StyledDrawer variant="permanent" open={open}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* Top Section: Toggle Button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: theme.spacing(0, 1),
                        ...theme.mixins.toolbar,
                        marginTop: '20px',
                    }}
                >
                    <IconButton
                        onClick={handleDrawerToggle}
                        sx={{
                            '&:hover': { backgroundColor: 'transparent' },
                            color: 'inherit',
                        }}
                    >
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Box>

                {/* Middle Section: Navigation Items */}
                <List sx={{ flexGrow: 1 }}>
                    {items.map(({ icon, text, path }) => (
                        <Tooltip key={text} title={text} placement="right" disableHoverListener={open}>
                            <ListItem
                                button
                                component={Link}
                                to={path}
                                selected={location.pathname === path}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    paddingLeft: open ? theme.spacing(2) : theme.spacing(1.5),
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: 'inherit',
                                        minWidth: 'unset',
                                        marginRight: open ? theme.spacing(2) : theme.spacing(1),
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, whiteSpace: 'nowrap' }} />
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>

                {/* Bottom Section: Logout Item placed above the Logo */}
                {open && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, p: 2 }}>
                        <Tooltip title="Logout" placement="right" disableHoverListener={open}>
                            <ListItem
                                button
                                onClick={handleLogout}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    paddingLeft: theme.spacing(2),
                                    width: '100%',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: 'inherit',
                                        minWidth: 'unset',
                                        marginRight: theme.spacing(2),
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" sx={{ opacity: 1, whiteSpace: 'nowrap' }} />
                            </ListItem>
                        </Tooltip>

                        {/* Logo Section */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </StyledDrawer>
    );
}

export default SidebarNew;
